import { useParams } from '@solidjs/router'
import TypeProofreadingView from '~/components/type-proofreading-view/type-proofreading-view'
import { TypeProofreadingParams } from '~/types/routes/series/type-proofreading'

export default function TypeProofreading() {
  const { id } = useParams<TypeProofreadingParams>()
  return (
    <>
      <TypeProofreadingView chapterId={id} />
    </>
  )
}
